<template>
      
    <div >
        <h2 class="page-headline" v-html="headline"></h2>
        <div class="deko-settings-big__line" :style="kSDekoline()"></div>
    </div>
   
</template>



<script>
import {useStore} from 'vuex'
// settings des players
// https://github.com/sampotts/plyr#options
// https://www.npmjs.com/package/@altinget/vue-plyr


export default {

    props: {
        headline: String,
        subline: String
    },


    setup(){ // props
        const store = useStore();
        // console.log(props)
        const kSDekoline = () => {
            // console.log("headline ksdekoline")
            if (store.state.kontrastSwitch == false) {
                return {
                        backgroundColor: '#ffffff',
                }
            } else {
                return {
                        backgroundColor: '#ffffff',
                }
            } 
        }

        return {
            kSDekoline
        }

    },

}
</script>



<style lang="scss" >

</style>