<template>

    <div class="modal container" :style="kSBackground()">

        <div class="basefloater-container" :style="kSBackground()">
            
            <div class="base-c1">
                
                <div class="settings-topline"><h4>{{contentui.ui.pincodeaufforderung[language]}}</h4><img src="../assets/svgs/close.svg" alt="" @click="closeModal()"></div>
                <h5>{{contentui.ui.pincode[language]}}</h5>
                <div class="deko-settings__line"></div>
                
                <div class="settings-container">  

                    <div class="nummern deselect">{{ codeArray[0] + '' + codeArray[1] + '' + codeArray[2] }}</div>
                    <div class="nummernlines deselect">___</div>

                    <div class="pin-pad deselect">
                        <div class="gradient-circle deselect" @click="setNum('1')">
                            1
                        </div>
                        <div class="gradient-circle deselect" @click="setNum('2')">
                            2
                        </div>
                        <div class="gradient-circle deselect" @click="setNum('3')">
                            3
                        </div>
                        <div class="gradient-circle deselect" @click="setNum('4')">
                            4
                        </div>
                        <div class="gradient-circle deselect" @click="setNum('5')">
                            5
                        </div>
                        <div class="gradient-circle deselect" @click="setNum('6')">
                            6
                        </div>
                        <div class="gradient-circle deselect" @click="setNum('7')">
                            7
                        </div>
                        <div class="gradient-circle deselect" @click="setNum('8')">
                            8
                        </div>
                        <div class="gradient-circle deselect" @click="setNum('9')">
                            9
                        </div>
                        <transition name="fadepin" mode="out-in" appear>
                        <div class="gradient-circle deselect" @click="setBack()" v-if="showBack==true">
                            ⌫
                        </div>
                        </transition>
                        <div class="gradient-circle last deselect" @click="setNum('0')">
                            0
                        </div>
                        <transition name="fadepin" mode="out-in" appear>
                        <div class="gradient-circle deselect" @click="setOk()" v-if="showOk==true">
                            ✓
                        </div>
                        </transition>
                    </div>

                </div>

                <div class="message-container">
                        
                        <transition name="fade" mode="out-in">
                            <p class="message" v-if="message!=' '">
                                {{ message }}
                            </p>
                        </transition>
                    </div>
                
            </div>

            <!-- <Message message="hallo" show="true"> </Message> -->
        </div>

    </div>

</template>



<script>
import { useStore } from 'vuex';
import { useRouter } from 'vue-router'  // , useRoute, useRouter
import { onMounted, ref  } from 'vue';  // inject, 

// import Message from './Message.vue'

export default {
    props: {
        contentui: Object,
        language: String
    },

    components: {
        // Message,
    },

    setup(props) {
        const store = useStore();
        const router = useRouter();

        const message = ref(" ");
        // console.log(props.contentui.ui.pincode)
        
        const closeModal= () => {
          store.state.pincodeModal = false;
        }

        // const setLanguage = (value) => {
        const codeArray = ref([" ", " ", " "]);
        const showOk = ref(false);
        const showBack = ref(false);

        const setNum = (value) => {
            if (codeArray.value[0] == " "){
                codeArray.value[0] = value
                showBack.value=true;
            } else if (codeArray.value[1] == " "){
                codeArray.value[1] = value
                // showBack.value=true;
            } else if (codeArray.value[2] == " ") {
                codeArray.value[2] = value
                showOk.value = true;
            }
        }

        const setBack = () => {
            if (codeArray.value[2] != " "){
                codeArray.value[2] = " ";
                showOk.value = false;
            } else if (codeArray.value[1] != " "){
                codeArray.value[1] = " ";
                // showOk.value = false;
            } else if (codeArray.value[0] != " "){
                codeArray.value[0] = " ";
                showBack.value = false;
            }
        }

        const setOk = () => {
            if (codeArray.value[0] != " " && codeArray.value[1] != " " && codeArray.value[2] != " " ) {
                // console.log("setOk");
                let tempPin = codeArray.value[0] + codeArray.value[1] + codeArray.value[2];
                
                if (props.contentui.pincodes.includes( tempPin) == true) {
                    // console.log(parseInt(codeArray.value[0]) -1 + codeArray.value[1])
                    // router.push({ name: "rundgangpage", params: { roomId: (parseInt(codeArray.value[0]) -1), pageId: parseInt(codeArray.value[1] + codeArray.value[2]) -1 }, props: true});
                    router.push({ path: "/rundgangpage" + "/" +  (parseInt(codeArray.value[0]) -1) + "/" +  (parseInt(codeArray.value[1] + codeArray.value[2]) -1) });
                    
                    store.state.roomId = parseInt(codeArray.value[0]) -1;
                    store.state.roomPageId = parseInt(codeArray.value[1] + codeArray.value[2]) -1;
                    store.state.maxRoomPage = store.state.contentWp[store.state.roomId].children.length
                    store.state.pincode = tempPin;
                    store.state.pincodeModal = false;
                
                } else {
                    // console.log("Pincode nicht verfügbar");
                    message.value = props.contentui.ui.pincodeerror[store.state.language]
                    setTimeout( () => {
                        message.value = " "
                    }, 3000 )

                }
            }
        }

        const kSBackground = () => {
               if (store.state.kontrastSwitch == false) {
                    return {
                         backgroundColor: '#007955',
                    }
               } else {
                    return {
                         backgroundColor: '#007955',
                    }
               } 
        }

        onMounted(() => {
           // console.log(props)
        })

        return {
          props,
          store,
          codeArray,
          closeModal,
          kSBackground,
          setNum,
          setBack,
          setOk,
          showOk,
          showBack,
          message
        }
    }

}
</script>



<style lang="scss" scoped>

.modal {
     position: fixed;
     z-index: 101;
     top: 0;
     left: 0;
     // background: rgb(214, 16, 16);
     width: 100%;
     height: 100vh;
     background: rgb(241, 3, 3);
}

.container {
    // position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    // background: rgb(241, 3, 3);
    //  width: 100%;
    //  // max-width: 1180px;
    //  height: 100vh;
}
.settings-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  // justify-content: space-around;
  justify-content: center;
  // justify-content: space-between;
  // align-content: space-around;
  align-content: space-around;
  margin-top: 1rem;
}

.setting-base {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    // justify-content: center;
    flex-direction: column;
    align-content: space-around;
}

.settings-topline {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between
}

.nummern {
  text-align: center;
//   margin-bottom: 2rem;
white-space: pre;
  font-size: 6.6rem;
  color: #ccc;
}

.nummernlines {
  text-align: center;
  margin-top: -6rem;
  margin-bottom: 3rem;
  font-size: 6.6rem;
  line-height: 5rem;
  color: #ccc;
}
.pin-pad {
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  gap: 1rem;
  // direction: rtl;
  .gradient-circle {
    color: #ccc;
    border: 2px solid #ffffff;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2em;
    height: 2em;
    font-size: 1.6rem;
    position: relative;
    padding: 2rem;
    box-sizing: border-box;
    &:hover {
      color: white;
      background: rgba(255, 255, 255, 0.0);
    }
    &:active {
      background: rgba(255, 255, 255, 0.65);
    }
  }
  .last {
    grid-column-start: 2;
  }

}

.message {
    text-align: center ;
    font-family: FontMedium;
    font-size: 1.5rem;
    color: "#ff0000"
  }

.message-container {
    justify-content: center;
    align-content: space-around;
    margin-top: 2rem;
    // width: 50%; 
    color: "#ffffff";
}

.fadepin-enter-from,
.fadepin-leave-to {
  transition: opacity 0.5s ease-out 0.0s;
  opacity: 0;
}

.fadepin-leave-from,
.fadepin-enter-to {
  transition: opacity 0.5s ease-out 0.0s;
  // opacity: 1;
}

</style>