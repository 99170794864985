<template>
     
     <div>
          <div class="intro-container">
               <div class="c1">
                    <h1 v-html="contentui.ui.maphead[language]"></h1>
                    <div class="deko__line" :style="kSDekoline()"></div>
                    <!-- :class="typoswitch ? 'h11':'h4'" -->
                    <p :class="store.state.typoSwitch ? 'larger':'leer'" v-html="contentui.ui.mapcopy[language]"></p>
                    <p :class="store.state.typoSwitch ? 'larger':'leer'" ><b v-html="contentui.ui.mapsub[language]"></b></p>
                    <!-- style="width: 500px; height: 500px; border:1px solid black;" -->
                    
                    <!-- -->
                    <!-- <panZoom selector="#g2" :options="{initialZoom: 1.0, minZoom: 0.5, maxZoom: 5, bounds: true, boundsPadding: 0.3}" style="border-color: #ffffff; border-width: 1.5px; border-style: solid;background-color: #ffffff" height="600px"> -->
                         <svg  id="svg" focusable="false" version="1.1" height="400px" width="100%" viewBox="0 0 1024 1024" style="enable-background: 0 0 1024 1024; background-color:#ffffff" xml:space="preserve">

                              <g id="g2" height="1024px" width="1024px">
                                   <image id="svg-image" href="/map/map-bg-2.jpg" height="1024px" width="1024px"  /> 
               
                                   <template v-for="raum, index in store.state.contentWp" :key="index"> 
                                        <g v-for="children, index2 in raum.children" :key="index2"> 
                                             <circle :id="children['custom_fields']['wpcf-pincode']" fill="#8B0F0D" stroke="none" :cx="children['custom_fields']['wpcf-pos'].split(',')[0]" :cy="children['custom_fields']['wpcf-pos'].split(',')[1] /1" r="12" @click="openRouterSite( children['custom_fields']['wpcf-pincode'] )">   
                                             </circle>
                                             <text :id="children['custom_fields']['wpcf-pincode']" fill="#fff" stroke="#none" :x="children['custom_fields']['wpcf-pos'].split(',')[0]" :y="5+parseInt(children['custom_fields']['wpcf-pos'].split(',')[1]) / 1" class="small" v-html="children['custom_fields']['wpcf-pincode']"  @click="openRouterSite( children['custom_fields']['wpcf-pincode'] )"></text>
                                        </g>
                                   </template>
                              </g>

                         </svg>
                    <!-- </panZoom> -->

                    <!-- <animate attributeName="opacity" dur="1s" values="0;1;0" repeatCount="indefinite" begin="0.1" /> -->


                    <!-- <panZoom selector="#g1" style="border-color: #ffffff; border-width: 1.5px; border-style: solid">
                         <svg height="210" width="400">
                              <g id="g1">
                                   <path d="M150 0 L75 200 L225 200 Z" />
                              </g>

                              <g id="g2">
                                   <path d="M150 0 L75 200 L225 200 Z" />
                              </g>
                         </svg>
                    </panZoom>  -->

                    <!-- <MapSvg></MapSvg> -->

                    <div class="home-footer-deko">
                         <div class="deko__line" :style="kSDekoline()"></div>
                         <p v-html="contentui.ui.links[language]"></p>
                    </div>     
  
               </div> 

          </div>
               
     </div>
     
</template>



<script>
import { useRouter } from 'vue-router'  // , useRoute, useRouter
import { useStore } from 'vuex'
import { onMounted  } from 'vue';  // onMounted


export default {

     components: {

     },

     props: {
          contentui: Object,
          content:Object,
          language: String
     },
     
     setup() {
          // const route = useRoute();
          const store = useStore();
          // console.log("store inited in homeview")
          const router = useRouter();
          var panzoom = require('panzoom');  // https://github.com/anvaka/panzoom
          let svgZoomer = "";
          
          // function getMousePositionSVG(event) {
          //      var point = createSVGPoint();
          //      point.x = event.clientX;
          //      point.y = event.clientY;
          //      console.log(point.x, point.y);
          //      point = point.matrixTransform(this.getScreenCTM().inverse());
          //      // console.clear();
          //      console.log(point.x, point.y);
          //      // return point;
          // }

          onMounted(() => {
               console.log("onMounted SVG")
               // const svg = document.getElementById('svg-image')
               // svg.addEventListener("mousedown", getMousePositionSVG, false)

               let element = document.getElementById('g2')
               svgZoomer = panzoom(element, {initialZoom: 0.3, minZoom: 0.3, maxZoom: 5, bounds: true, boundsPadding: 0.3,  autocenter: false,
                    onClick(e) {
                         console.log("e.target.id", e.target.id)
                         if ( (e.target.id != undefined) && (e.target.id != '') ){
                              openRouterSite(e.target.id);
                         } 
                    }
               })
          })
        
          
          // eslint-disable-next-line no-unused-vars
          const openRouterSite = (xxx) => {
               console.log("openRouterSite", xxx)
               if (xxx != "svg-image" && xxx != "svg"){
                    let ary = xxx.split("");
                    store.state.roomId = parseInt(ary[0]) -1;
                    store.state.roomPageId = parseInt(ary[1]+ ary[2]) -1;
                    store.state.navIndex = 1;

                    if ( store.state.roomPageId < 9 ) {
                         store.state.pincode = String(store.state.roomId +1) + "0" + String(store.state.roomPageId +1);
                    } else {
                         store.state.pincode = String(store.state.roomId +1 ) + "" + String(store.state.roomPageId +1);
                    }

                    // let room = parseInt(ary[0]) -1;
                    // let page = parseInt(ary[1]+ ary[2]) -1;
                    // store.state.roomId = 0;
                    // store.state.roomPageId = 0;
                    router.push({ path: "/rundgangpage" + "/" + store.state.roomId + "/" + store.state.roomPageId }) 
               }
           
        }

     //    const onClickSvg = (e) => {
     //        console.log(e.offsetX, e.offsetY);
     //        // const { farthestViewportElement: svgRoot } = e.target;
     //        // const dim = svgRoot.getBoundingClientRect();
     //        // const x = e.clientX - dim.left;
     //        // const y = e.clientY - dim.top;
     //        // console.log(`x: ${x}, y: ${y}`);
     //    };

          const kSDekoline = () => {
               // console.log("home ksdekoline")
               if (store.state.kontrastSwitch == false) {
                    return {
                         backgroundColor: '#ffffff',
                    }
               } else {
                    return {
                         backgroundColor: '#ffffff',
                    }
               } 
          }

          return{
               
               kSDekoline,
               openRouterSite,
               // onClickSvg,
               svgZoomer,
               store,
          }
     },

    
}
</script>



<style lang="scss" scoped>
.intro-container {
     display: grid;
     grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
     grid-template-areas: "c1 c1 c1 c1 c1 c1 c1 c1 c1 c1 c1 ." 
                          ". . . . . . . . . . . ."
                          ". . . . . . . . . . . ."; 
     text-align: left;
     // background-color: chocolate;
     padding-top: 40px;
     padding-left: 36px;
     padding-right: 36px;
}

.c1 { 
     grid-area: c1; 
}

.home-footer-deko {
     padding-top: 10rem;
}


// svg focus {
//     outline: none;
//   }

// .small {
//         font-family: FontBold;
//         font-size: 15px;
//         text-anchor: middle;
//     }

.st0{fill:none;stroke:#EEEEEE;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
.st1{fill:none;stroke:#94A78F;stroke-width:0.288;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
.st2{fill:none;stroke:#94A78F;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
.st3{fill:none;stroke:#EEEEEE;stroke-width:0.288;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
.st4{fill:none;stroke:#DD0000;stroke-width:0.504;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
.st5{fill:#EEEEEE;}
.st6{fill:none;stroke:#94A78F;stroke-width:0.72;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}

.small {
     font-family: FontBold;
     font-size: 11px;
     text-anchor: middle;
}
</style>