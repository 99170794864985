<template>
     <div class="base-container" >
          
          <transition name="fadepage" appear mode="out-in">
               <div class="base-c1" :class="store.state.typoSwitch ? 'larger':'normal'" v-if="content!=''" style="white-space: pre;" v-html="filterBR(content['content'].rendered)" :key="store.state.language">

               </div>
          </transition>

     </div>   
</template>



<script>
import { ref, onMounted} from 'vue'; // ref, , computed
import { useStore } from 'vuex'


export default {
     // inheritAttrs: false,
     // props: {
     //      contentui:Object,
     //      content: Object,
     //      contentwp: Object,
     //      zitate: Array,
     //      language: String,
     // },

     setup() {
          const store = useStore();
          let apiURL = 'https://architekturspur-prignitz-museum.blcms.de/wp-json/wp/v2/posts/29';
          const content = ref('')

          const filterBR = (xxx) => {
               // console.log(xxx);
               // return "<H1>hallo</H1>"
               // return xxx.replace('<br />','<br>' );
               let yyy = xxx.replace(/(?:\r\n|\r|\n)/g, '');
               // console.log(yyy)
               return  yyy
               // return "<H1>hallo</H1>"
          }


          onMounted(() => {
               fetch(apiURL, { method: 'get', headers: { 'content-type': 'application/json' } } )
                    .then(res => {
                         if (!res.ok) {
                              // console.log("loaded NOOK")
                              // create error instance with HTTP status text
                              const error = new Error(res.statusText);
                              error.json = res.json();
                              throw error;
                         }
                         return res.json();
                    })
                    .then(json => {
                         // console.log("content")
                         // console.log("json", json)
                         content.value = json;
                         // console.log(content.value['toolset-meta'].mlpost['posttext_' + store.state.language].raw)
                         // console.log(content.value['content'].rendered)
                    })
                    .catch(err => {
                         console.log("error", err)
                         const error = new Error();
                         error.value = err;
                         if (err.json) {
                              return err.json.then(json => {
                              error.value.message = json.message;
                              });
                         }
                         })
                    .then(() => {
                         // contentLoaded.value = true;
                    });
          })

          return {
               content,
               store,
               filterBR

          }

     },
     // components: {
     // },
     methods: {
    
     },
     
}
</script>



<style lang="scss">

.normal p {

}

.larger p {
     font-size: 20px;
     line-height: 28px;
}

</style>