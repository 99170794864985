import { createRouter, createWebHistory } from 'vue-router'

import HomeView from '../views/HomeView.vue'
import MapView from '../views/MapView.vue'
import ContentView from '../views/ContentView.vue'
// import VideoView from '../views/VideoView.vue'
// import VideoViewPlyr from '../views/VideoViewPlyr.vue'
// import ReiseView from '../views/ReiseView.vue'
import ImpressumView from '../views/ImpressumViewWP.vue'
// import BibelView from '../views/ContentBibelView.vue'
// import BookView from '../views/ContentBookView.vue'
// import BookTwoView from '../views/ContentTwoBookView.vue'
// import RueckreiseBibelView from '../views/RueckreiseBibelView.vue'
// import FaktenBibelView from '../views/FaktenBibelView.vue'

// import { useStore } from 'vuex'
// Vue.use(VueRouter);

// function checkfalseUrl(to) {
//   console.log("checkfalseUrl", to)
//   // if (to.hash) return { path: to.path, query: to.query, hash: '' }
// }

var routes = [

  {
    path: "/",
    name: "homepage",
    component: HomeView
  },

  {
    path: "/",
    name: "",
    component: HomeView
  },

  {
    path: "/MapPage",
    name: "mappage",
    component: MapView
  },

  {
    path: "/rundgangPage/:roomId/:pageId?",
    name: "rundgangpage",
    component: ContentView
  },

//   {
//     path: "/introPage",
//     name: "intropage",
//     component: IntroView
//   },

//   {
//     path: "/videoPage",
//     name: "videopage",
//     component: VideoView
//   },

//   {
//     path: "/videoPagePlyr",
//     name: "videopageplyr",
//     component: VideoViewPlyr
//   },

  {
    path: "/impressumPage",
    name: "impressumpage",
    component: ImpressumView
  },
  { 
    path: '/:pathMatch(.*)*',
    name: "home",
    component: HomeView
  } // ,
  // {
  //   path: "/:catchAll(.*)",
  //   name: "",
  //   component: HomeView,
  //   beforeEnter: checkfalseUrl
  // },
];

// mode: 'hash',
// base: process.env.BASE_URL,

const router = createRouter({
  //history: createWebHistory(),
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHistory(process.env.BASE_URL),
  routes,
  // https://stackoverflow.com/questions/71196755/linking-router-link-and-scrollbehavior-not-working-vue-3-and-ionic-6
  // eslint-disable-next-line no-unused-vars
  
  // scrollBehavior: (to, from, savedPosition) => {
  //   console.log("scrollBehavior", window.location.href)
  //   // window.scrollTo(0, 0);
  //   if (to.hash) {
  //     console.log("scrollBehavior", window.location.href)
  //     const el = window.location.href.split("#")[1];
  //     if (el.length) {
  //       document.getElementById(el).scrollIntoView({ behavior: "smooth" });
  //     }
  //   } else if (savedPosition) {
  //     return savedPosition;
  //   } else {
  //     document.getElementById("app").scrollIntoView({ behavior: "smooth" });
  //   }
  //   return { x: 0, y: 0 }
  // },

  // eslint-disable-next-line no-unused-vars
  // beforeEach :(to, from) => {
    // if(to.name === "Home" && !to.query.hasOwnProperty("pageId")){
    //     to.query.pageId = "7"
    // }
    
    // console.log("to", to)
    // if(!to.query.hasOwnProperty("pageId")){
    //   to.query.pageId = "7"
    // }
  
  // }
})


// let store = useStore()

// nicht hier initialisisert, der store ist noch nicht verfügbar
// // eslint-disable-next-line no-unused-vars
// router.beforeEach((to, from) => {
  
//   console.log("to", to.params )
//   // Object.prototype.hasOwnProperty.call(object, "objectProperty");
//   if(!Object.prototype.hasOwnProperty.call(to.params, "pageId") ) {
//     to.params.pageId = 1;
//   } else {
//     console.log("EXXXTERN PageID", to.params.pageId)
//     // console.log(store)
//     store.state.roomPageId = to.params.pageId;
//   }
//   console.log("to", to)

// })


export default router