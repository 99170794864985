<template>
     
     <div>
          <div class="intro-container">
               <div class="c1">
                    <h1 v-html="contentui.ui.introhead[language]"></h1>
                    <div class="deko__line" :style="kSDekoline()"></div>
                    <!-- :class="typoswitch ? 'h11':'h4'" -->
                    <p :class="store.state.typoSwitch ? 'larger':'leer'" v-html="contentui.ui.introcopy[language]"></p>
                    <p :class="store.state.typoSwitch ? 'larger':'leer'" ><b v-html="contentui.ui.introsub[language]"></b></p>
                    
                    <div class="kachel" @click="startRundgang()" :style="kSKachel()" >
                         <p v-html="contentui.ui.starten[language]" :style="kSKachelFont()" :class="store.state.typoSwitch ? 'larger':'leer'"></p>
                    </div>
                    <!-- <pinpad></pinpad> -->

                    <div class="home-footer-deko">
                         <div class="deko__line" :style="kSDekoline()"></div>
                         <p v-html="contentui.ui.links[language]"></p>
                    </div>     

                    <div>
                         <nav>
                              <ul class="main-footer__links">
                                   <!-- <li class="main-footer__link">
                                        <a class="deselect" @click="openKontakt()"> {{ contentui.footer[language][0] }} </a>
                                   </li> -->
                                   <li class="main-footer__link">
                                        <a class="deselect" @click="openImpressum()">{{ contentui.footer[language][0] }}</a>
                                   </li>
                                   <li class="main-footer__link">
                                        <a class="deselect" @click="openPincode()">{{ contentui.footer[language][2] }}</a>
                                   </li>
                                   <!-- <li class="main-footer__link">
                                        <a class="deselect" @click="openQrcodeScanner()">{{ contentui.footer[language][2] }}</a>
                                   </li> -->
                                   <!-- <li class="main-footer__link">
                                        <a class="deselect" @click="openPincode()">{{ contentui.footer[language][1] }}</a>
                                   </li> -->
                              </ul>
                    
                         </nav>
                    </div>
               </div>
          </div>
               
     </div>
     
</template>



<script>
// import pinpad from '../components/pinpad.vue'
import { useRouter } from 'vue-router'  // , useRoute, useRouter
import { useStore } from 'vuex'


export default {

     components: {
          // pinpad,
     },

     props: {
          contentui: Object,
          content:Object,
          language: String
     },
     
     setup() {
          // const route = useRoute();
          const store = useStore();
          // console.log("store inited in homeview")
          const router = useRouter();

          const startRundgang = () => {
               // console.log("startRundgang")
               store.state.roomId = 0;
               store.state.roomPageId = 0;
               store.state.pincode = "101";
               store.state.navIndex = 2;
               // router.push({ name: "rundgangpage", params: { roomId: store.state.roomId}, props: true})   // , language: store.state.language
               router.push({ path: "/rundgangpage" + "/" + store.state.roomId + "/" + store.state.roomPageId})   // , language: store.state.language
          }

          const openQrcodeScanner = () => {
               store.state.qrcodeModal = !store.state.qrcodeModal
          }

          const openKontakt = () => {
               store.state.showRundgang = false;
               // router.push({ name: 'contactpage', params: { roomId: ""}, props: true})
               router.push({ path: '/contactpage'})
               // router.push({ name: 'contactpage',props: true})
               // pushPage('contactPage')
          }
          
          const openPincode = () => {
               store.state.pincodeModal = true; //!store.state.pinCodeModal
          }

          const openImpressum = () => {
               store.state.showRundgang = false;
               store.state.navIndex = -1;
               // router.push({ name: 'impressumpage', params: { roomId: ""}, props: true})
               router.push({ path: '/impressumpage'})
               // pushPage('impressumPage')
          }

          const kSKachel = () => {
               if (store.state.kontrastSwitch == false) {
                    return {
                         backgroundColor: '#ffffff',
                         // border: '1px solid #1e211d',
                    }
               } else {
                    return {
                         backgroundColor: '#ffffff',
                         // border: '1px solid #000000',
                    }
               } 
        }

       
        const kSKachelFont = () => {
               if (store.state.kontrastSwitch == false) {
                    return {
                         color: '#000000',
                    }
               } else {
                    return {
                         color: '#000000',
                    }
               } 
        }

     const kSDekoline = () => {
          // console.log("home ksdekoline")
          if (store.state.kontrastSwitch == false) {
               return {
                    backgroundColor: '#ffffff',
               }
          } else {
               return {
                    backgroundColor: '#ffffff',
               }
          } 
        }

          return{
               startRundgang,
               openQrcodeScanner,
               openKontakt,
               openImpressum,
               openPincode,
               kSDekoline,
               kSKachel,
               kSKachelFont,
               // openFoerderer,
               store,
          }
     },
}
</script>



<style lang="scss" scoped>
.intro-container {
     display: grid;
     grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
     grid-template-areas: "c1 c1 c1 c1 c1 c1 c1 c1 c1 c1 c1 ." 
                          ". . . . . . . . . . . ."
                          ". . . . . . . . . . . ."; 
     text-align: left;
     // background-color: chocolate;
     padding-top: 40px;
     padding-left: 36px;
     padding-right: 36px;
}

.c1 { 
     grid-area: c1; 
}

.home-footer-deko {
     padding-top: 10rem;
}

</style>