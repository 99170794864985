<template>
  
  <div class="content">

      <Header v-if="uiLoaded==true && Object.keys(contentUi).length > 0" :contentui="contentUi" :language="store.state.language"></Header>

      <main :class="store.state.kontrastSwitch==true ? 'main-bgcolor': ''"  v-if="uiLoaded==true && Object.keys(contentUi).length > 0">
                  
        <!-- name="fadepage" :content="content"   :key="(route.path && route.params.roomId)"  -->
        <router-view v-if="uiLoaded==true && contentLoaded==true"  v-slot="{ Component }"  :contentui="contentUi"   :contentwp="contentWp" :language="store.state.language" > 
            <transition name="slidefade"  mode="out-in" :key="(route.path + '_' + route.params.roomId + '_' +  route.params.roomPageId)">
              <component :is="Component" class="animated"  :key="(route.path + '_' + route.params.roomId + '_' +  route.params.roomPageId)"/>
            </transition>
        </router-view>

        <div class="spacer"> </div>

      </main>

      <!-- <div class="floater">
        <transition-group name="movefloater" mode="out-in" key="store.state.language">
          <FloaterSettings :contentui="contentUi" :language="store.state.language" v-if="uiLoaded==true && Object.keys(contentUi).length > 0  && store.state.showSettings == true" :key="2"></FloaterSettings >
          <FloaterLanguage :contentui="contentUi" :language="store.state.language" :content="content" v-if="uiLoaded==true && Object.keys(contentUi).length > 0 && store.state.showLanguage == true" :key="3"></FloaterLanguage>
        </transition-group>
      </div> -->

      <Footer v-if="uiLoaded==true && Object.keys(contentUi).length > 0" :contentui="contentUi" :language="store.state.language"></Footer>
      <BaseFooter v-if="uiLoaded==true && Object.keys(contentUi).length > 0 && route.path!='/'" :content="contentUi" :language="store.state.language"></BaseFooter>
      <!-- && route.path!='/'  -->
  </div>

  <!-- <div class="floaterbottom">
      <transition name="movefloaterup" mode="out-in" key="store.state.language">
        <FloaterPincode :contentui="contentUi" :language="store.state.language" :content="content" v-if="uiLoaded==true && Object.keys(contentUi).length > 0 && store.state.showPincode == true" ></FloaterPincode>
      </transition>
  </div> -->

  <transition name="fadepage" appear mode="out-in">
      <menuModal v-if="store.state.menuModal==true" :contentui="contentUi" :language="store.state.language"></menuModal>
  </transition>

  <!-- <transition name="fadepage" appear mode="out-in">
    <QrcodeModal v-if="store.state.qrcodeModal==true&&uiLoaded==true" :content="contentUi"></QrcodeModal>
  </transition> -->
  
  <!-- <transition name="fadepage" mode="out-in" appear :key="getPage()">     -->
      <!-- <MapFlex imgurl="/images/xxx.jpg" :width="2000" :height="2000" :enablerotation="false"></MapFlex> -->
  <!-- </transition> -->

  <transition name="fadepage" appear mode="out-in">
      <PincodeModal v-if="store.state.pincodeModal==true&&uiLoaded==true" :contentui="contentUi" :language="store.state.language"></PincodeModal>
  </transition>

  <!-- <transition name="fadesplash"  mode="out-in">
      <blSplash v-if="uiLoaded==false"></blSplash>
  </transition> -->
  
  <!-- <VueImage></VueImage> -->
  
 <!-- overlay-transition="vfm-fade" content-transition="vfm-fade"  -->
  <!-- <vue-final-modal v-model="store.state.showModal" class="modal-container bg-white opacity-100 w-full h-full" content-class="modal-content bg-white w-full h-full " 
                    :lock-scroll="true"
                    :hide-overlay="true"
                    :click-to-close="false"
                    :escToClose="true"
                    :reserveScrollBarGap="true"              
    >

      <div class="fixed top-24 right-0 deselect flex flex-row-reverse self-end mt-2 opacity-100 pointer-event-auto pr-2">
        <img src="./assets/svgs/button-close-2.svg" alt="" class=""  @click="showImageFullScreen()">
      </div>

      <div class="w-full h-full bg-white flex flex-col">
          <img src="./assets/map/map-bg-2.jpg" alt="">
          <div class="flex flex-col text-center w-1/1 justify-self-center h-4/5">
          </div>
      </div>

  </vue-final-modal>

  <ModalsContainer /> -->

 

</template>



<script>
// import QrcodeModal from './components/QrcodeScanner.vue'
import PincodeModal from './components/PincodeModal.vue'
// import MapFlex from './components/MapStaticFlexible.vue'
// import QrcodeModal from './components/QrcodeScannerAlternative3.vue'
// import QrcodeModal from './components/QrcodeScannerAlternative1.vue'
// import '@coddicat/vue-pinch-scroll-zoom/dist/style.css';
// import PinchScrollZoom from "@coddicat/vue-pinch-scroll-zoom/";  // , { PinchScrollZoomEmitData } 


import menuModal from './components/Menu.vue'

import Footer from './components/Footer.vue'
import BaseFooter from './components/BaseFooter.vue'
import Header from './components/Header.vue'
// import blSplash from './components/blSplash.vue'

import { onMounted, ref  } from 'vue'; // ref, onMounted, computed, , inject
import { useRouter, useRoute } from 'vue-router'  // 
import { useStore } from 'vuex';
import useEmitter from '@/composables/useEmitter';
import { publicPath } from '../vue.config';

import { VueFinalModal, ModalsContainer } from 'vue-final-modal'; 


// import VueZoomable from "vue-zoomable";
// import "vue-zoomable/dist/style.css";



export default {
  name: 'App',
  
  components: {
    // QrcodeModal,
    PincodeModal,
    menuModal,
    Header,
    Footer,
    BaseFooter,
    // VueZoomable,
    // PinchScrollZoom,
    // SvgPanZoom,
    // blSystemInformation
    // blSplash,
    VueFinalModal,
    ModalsContainer,
    // VueImage,
    // MapFlex,
    // blDemoOverlay,
    // FloaterSettings,
    // FloaterLanguage,
    // FloaterPincode,
    // blLayoutGrid
  },
  

  setup(){
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const emitter = useEmitter();
    
    let uiLoaded = ref(false);
    let contentLoaded = ref(false);

    let errorLoads =[];
    let toLoads = [];
    let loadtext = ref("");
    let contentUi = ref({});
    let content = ref({});
    let contentWp = ref({});
    let imageArray = []

    const showImageFullScreen = () => {
      // console.log("showImageFullScreen");

      if (store.state.showModal==false){
        store.state.showModal = true;

      } else {
       
        store.state.showModal = false;
        
      }

      console.log("showModal", store.state.showModal)
    }


    // nicht im router script initialisisert
    // eslint-disable-next-line no-unused-vars
    router.beforeEach((to, from) => {
      // console.log("to", to.params )
      // Object.prototype.hasOwnProperty.call(object, "objectProperty");
      if( !Object.prototype.hasOwnProperty.call(to.params, "pageId") ) {
        to.params.pageId = 1;
        store.state.roomPageId = 1;
      } else if (to.params.pageId == "") {
        to.params.pageId = 1;
        store.state.roomPageId = 1;
      } else {
        // console.log("EXXXTERN PageID", to.params.pageId)
        // console.log(store)
        store.state.roomPageId = parseInt(to.params.pageId);
      }
      // console.log("to", to)
    })

    const resetApp = () => {
      uiLoaded.value = false
      contentLoaded.value = false
    }

    const introPage = () => {
      store.state.roomId = 0;
      store.state.roomPageId = 1;
      router.push("/introPage").catch(()=>{});
    }

    const pushPage = (page) => {
      store.state.roomId = 0;
      store.state.roomPageId = 1;
      router.push("/" + page).catch(()=>{});
    }

    const getDebug= () => {
      return store.state.debug;
    }

    const initPincodes = () => {

      // console.log("initPincodes");
      //console.log(contentWp.value);
      for (let p = 0; p < contentWp.value.length; p++) {
        // console.log("yyy", p)
        for (let index = 0; index < contentWp.value[p].children.length; index++) {
          const element = contentWp.value[p].children[index];
          // console.log(element["custom_fields"]["wpcf-pincode"]);
          contentUi.value.pincodes.push( element["custom_fields"]["wpcf-pincode"] )
        }    
      }

      // console.log(contentUi.value.pincodes)

    }
    
    const  scalingHandler = (e) => {
          console.log(e);
    }

    const reset =() => {
      this.$refs.zoomer.setData({
        scale: 1,
        originX: 0,
        originY: 0,
        translateX: 0,
        translateY: 0        
      });
    }


    // IMAGE LOADING METHODS
    const checkLoadingFinishedOK= (url) => { // (url){
        // console.log("checkLoadingFinishedOK")
        let index = toLoads.indexOf(url);
        loadtext = url;
        if (index > -1) {
          toLoads.splice(index, 1);
        }
        // console.log(this.toLoads);
        checkLoadingFinished();
        // console.log("OK");
    }

    const checkLoadingFinishedNOOK = (url) => { //(url){
        var index = toLoads.indexOf(url);
        if (index > -1) {
          toLoads.splice(index, 1);
        }
        // console.log(this.toLoads);
        errorLoads.push(url);
        checkLoadingFinished();
        console.log("NO OK");
    }

    const checkLoadingFinished = () => {
        if (toLoads.length==0){
          // loaded.value = true;
          if (errorLoads.length == 0){
              store.dispatch('setAppLoaded');  // direkt freischalten des splash screen
          } else {
            // headertext= "Fehlende Medien";
            uiLoaded.value = true; // button auf dem splash screen anzeigen
            loadtext.value = errorLoads;
          }
          // console.log ("END OF LOADING");

          uiLoaded.value = true;
          // console.log("uiLoaded", uiLoaded.value)
        }
    }

    const loadOneImage = (url) => { // (url) {
        // var self = this;
        // console.log(" **** url laden versuch: "+ url)
        return new Promise(function(resolve, reject) {
          let img = new Image()
          // self = this
          img.onload = function() {
            // console.log("loaded:"+ url)
            checkLoadingFinishedOK(url);
            resolve(url)
          }
          img.onerror = function() {
            console.log("konnte das nicht laden:"+ url)
            checkLoadingFinishedNOOK(url);
            reject(url)
          }
          img.src = url
        })
    }

    const contentLoad = () => {
      
      // console.log("contentLoad", contentLoaded.value)
      // console.log("contentUi debug", contentUi.value.debug)
      if (process.env.NODE_ENV === "production"){
        var base_url = "https://architekturspur-prignitz-museum.blcms.de/"; // "https://www.bluelemon.de/demo/AL/mediaguide_v3/" // "." + publicPath
        base_url = window.location.origin +"/" + publicPath //   
      } else {
        base_url = window.location.origin +"/" + publicPath //   
        // base_url =  "." + publicPath
      }
    
      // fetch('./static/contentUi.json', { method: 'get', headers: { 'content-type': 'application/json' } } )
      // fetch(base_url + 'content.json', { method: 'get', headers: { 'content-type': 'application/json' } } )
      //     .then(res => {
      //         if (!res.ok) {
      //           console.log("loaded NOOK")
      //           // create error instance with HTTP status text
      //           const error = new Error(res.statusText);
      //           error.json = res.json();
      //           throw error;
      //         }
      //         return res.json();
      //     })
      //     .then(json => {
      //         // console.log("content")
      //         // console.log("json", json)
      //         content.value = json;
      //         store.state.content = json;
      //     })
      //     .catch(err => {
      //         console.log("error", err)
      //         const error = new Error();
      //         error.value = err;
      //         if (err.json) {
      //           return err.json.then(json => {
      //             error.value.message = json.message;
      //           });
      //         }
      //     })
      //     .then(() => {
      //       // contentLoaded.value = true;
      //     });


      // der eigentliche Datenteil, wird nach und nach content.json ersetzen
      // https://architekturspur-museum-prignitz.blcms.de/?json=get_page_index&custom_fields=*&dev=0&count=800
      // https://architekturspur-prignitz-museum.blcms.de/api/get_page_index/?custom_fields=*&dev=0&count=800/
      fetch(base_url + 'contentwp.json', { method: 'get', headers: { 'content-type': 'application/json' } } )
      
          .then(res => {
              if (!res.ok) {
                console.log("loaded live content NOOK")
                console.log(res);
                // create error instance with HTTP status text
                const error = new Error(res.statusText);
                error.json = res.json();
                throw error;
              }
              return res.json();
          })
          .then(json => {
              // console.log("live content loaded")
              // console.log("json", json)
              contentWp.value = json.pages;
              store.state.contentWp = json.pages;
              contentLoaded.value = true;

              initPincodes();

          })
          .catch(err => {
              console.log("error", err)
              const error = new Error();
              error.value = err;
              if (err.json) {
                return err.json.then(json => {
                  error.value.message = json.message;
                });
              }
          })
          .then(() => {
            contentLoaded.value = true;
          });
      
    }


    const uiLoad = () => {
      // console.log("uiLoad", uiLoaded.value)
      // console.log("contentUi debug", contentUi.value.debug)
      if (process.env.NODE_ENV === "production"){
       var base_url = "https://architekturspur-prignitz-museum.blcms.de/" // "." + publicPath
        base_url =  window.location.origin +"/" +publicPath //   
      } else {
        base_url =  window.location.origin +"/" +publicPath //   
        // base_url =  "." + publicPath
      }
      
      fetch(base_url + 'contentUi.json', { method: 'get', headers: { 'content-type': 'application/json' } } )
          .then(res => {
              if (!res.ok) {
                console.log("loaded NOOK")
                // create error instance with HTTP status text
                const error = new Error(res.statusText);
                error.json = res.json();
                throw error;
              }
              return res.json();
          })
          .then(json => {
              // console.log("loaded")
              // console.log("json", json)
              contentUi.value = json;
              store.state.contentUi = json;
              store.state.debug = contentUi.value.debug;
              // console.log("contentUi", contentUi.value)
            
              imageArray.push(base_url + "/images/bluelemon_logo.jpg");
              loadOneImage(imageArray[0])
             
              uiLoaded.value = true;

          })
          .catch(err => {
              console.log("error", err)
              const error = new Error();
              error.value = err;
              if (err.json) {
                return err.json.then(json => {
                  error.value.message = json.message;
                });
              }
          })
          .then(() => {
            uiLoaded.value = true;
          });
    }

    onMounted(() => {
      
        // let element = document.getElementById('iArea');
        // iZoomer = panzoom(element, {initialZoom: 0.3, minZoom: 0.3, maxZoom: 5, bounds: true, boundsPadding: 0.3,  autocenter: false,
        //     onClick(e) {
        //         console.log("e.target.id", e.target.id)
        //         if ( (e.target.id != undefined) && (e.target.id != '') ){
        //               // openRouterSite(e.target.id);
        //         } 
        //     }
        // })

                              
      window.addEventListener('keydown', (e) => {
            if(e.key =="d"){
              //  this.$store.dispatch('switchDebug'); 
              store.state.debug = !store.state.debug;
            }
          }, {passive: true});
      
      // console.log("route.path", route.path);

      resetApp()
      
      if (uiLoaded.value == false){
        // console.log("ui laden init")
        uiLoad();
      }
      if (contentLoaded.value == false){
        // console.log("content laden init")
        contentLoad();
      }
      // eslint-disable-next-line no-unused-vars
      emitter.on("GO_HOME", message => {
          // console.log("GO HOME in APP", message)
          store.state.roomId = 0;
          store.state.roomPageId = 1;
          store.state.showRundgang = false;
          router.push("/").catch(()=>{});
          store.state.navIndex = -1;
          // resetApp()
      })
      // eslint-disable-next-line no-unused-vars
      emitter.on("GO_UP", message => {
          // console.log("GO UP in APP", message)
      })

      // console.log(process.env.NODE_ENV, publicPath)

      /* When the user scrolls down, hide the navbar. When the user scrolls up, show the navbar */
      var prevScrollpos = window.pageYOffset;
      // alert (prevScrollpos);
      window.onscroll = function() {
        var currentScrollPos = window.pageYOffset;
        // alert (currentScrollPos);
        // console.log(prevScrollpos, currentScrollPos);  // && currentScrollPos < 30    || (currentScrollPos == null)   && ( currentScrollPos < 50 ) 
        if ( ( (prevScrollpos > currentScrollPos)  ||  ( currentScrollPos < 50 )  ||  ( currentScrollPos == undefined ) ) ){
          document.getElementById("main-header").style.top = "0rem";
        } else {
          document.getElementById("main-header").style.top = "-8rem"; // "-70px";
          store.state.showSettings = false;
          store.state.showLanguage = false;
        }
        prevScrollpos = currentScrollPos;
      }
    })

   

    return {
      uiLoaded,
      contentLoaded,
      resetApp,
      route,
      router,
      introPage,
      pushPage,
     
      store,
      content,
      contentWp,
      contentUi,
      scalingHandler,
      reset,

      getDebug,
      showImageFullScreen,

      // iZoomer
    }

  }
 
}
</script>



<style lang="scss">
@import "css/variables.scss";

#app {
  font-family: FontLight, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  margin: 0;
  height: 100%;
}

.content {
  text-align: left;
  margin: 0;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 7.0rem auto  fit-content(8rem) 0;   /* erster Wert Abstand nach oben */ /* fit-content(8rem)  5rem */
  grid-template-areas: "header"
                       "main"
                       "footer"
                       "basefooter";
  /* padding-top: 3.5rem; */
  height: 100%;
}

.floater {
  position: fixed;
  top: 100px;
  width: 100%;
  max-width: 1180px;
  height: auto;
  margin: auto;
  align-items: center;
}

.floaterbottom {
  position: sticky;
  bottom: 0px;
  margin-bottom: 0px;
  width: 100%;
  height: auto;
}

main {
  /* grid-row: 2 / 3; */
  grid-area: main;
  background-color: $backgroundcolor;
  /* margin-bottom: 2rem; */
}

.main-bgcolor {
  background-color: black;
}
/* footer {
  background-color: orange;
} */
</style>

<style lang="scss" scoped>
button {
  background-color: #ffe601; 
  border-radius: 8px;
  border: none;
  color: rgb(0, 0, 0);
  margin: 0.4rem;
  padding: 0.8rem 1.6rem;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1.0rem;
}

.animated {
  transition: all 0.5s;
  // display: flex;
  width: 100%;
}

.spacer {
  height: 20px;
}


.modal-full {
  width: 100%;
  height: 100%;
  background-color: red;
}
</style>