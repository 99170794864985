<template>

     <div>


          <div>

               <!-- 576px für Porträt-Handys
               768px für Tablets
               992px für Laptops
               1200px für große Geräte -->

               <div class="basefull-container">
                    <div class="base-c1"> 
                         <h4 :class="store.state.typoSwitch ? 'larger':'leer'">Bitte auswählen:</h4>
                         <!-- :space-between="12"
                         :slides-per-view="4" -->
                         <swiper class="swiper-container" ref="swiper" 
                              :pagination="{clickable: true }"
                              :modules="modules"
                              :centeredSlides="false"
                              :centeredSlidesBounds="false"
                              :loop="false"
                              :simulate-touch="true"
                              :allow-touch-move="true" 
                              @swiper="onSwiper"
                              @slideChange="onSlideChange"
                              :breakpoints="{
                                   '240': {
                                        slidesPerView: 2,
                                        spaceBetween:8,
                                   
                                   },
                                   '480': {
                                        slidesPerView: 3,
                                        spaceBetween:8,
                                   
                                   },
                                   '576': {
                                        slidesPerView: 3,
                                        spaceBetween: 8,
                                   
                                   },
                                   '768': {
                                        slidesPerView: 4,
                                        spaceBetween:24,
                                   
                                   },
                                   '992': {
                                        slidesPerView: 5,
                                        spaceBetween:32,
                                   
                                   },
                                   '1200': {
                                        slidesPerView: 6,
                                        spaceBetween:32,
                         
                                   },
                              }"

                         >    
                              <swiper-slide v-for="item, index in contentwp" :key="index" >
                                   <div class="raumkachel" :style="index==store.state.roomId ? kSKachelActive():kSKachel()" @click="contentRoom(index)" :class="store.state.typoSwitch ? 'larger':'leer'"> 
                                        <p :class="store.state.typoSwitch ? 'larger':'leer'" :style="index==store.state.roomId ? kSKachelFontActive():kSKachelFont()" v-html="item.custom_fields['wpcf-raum_' + store.state.language]">  </p> 
                                   </div>  
                              </swiper-slide>
                              
                         </swiper>
                    </div>
               </div>

               <transition name="fadepageslow" mode="out-in" appear> 
               <div :key="store.state.roomId">

                    <div class="basefull-container" >
                         <div class="base-c1"> 
                              <div class="headline-container">
                                   <h1 class="deselect" v-html="contentwp[store.state.roomId].custom_fields['wpcf-raum_' + store.state.language]"></h1>
                                   <hr class="deselect" style="opacity: 0.0">
                              </div>
                              <hr :style="kSHr()">
                         </div>
                    </div>
                    
                    <!-- <div class="basefull-container" :style="kSBackground()" v-if="contentwp[store.state.roomId].custom_fields['wpcf-raum_' + store.state.language] != null"> -->
                         <!-- <div class="base-c1"> 
                                   <PageHeadline :headline="contentwp[store.state.roomId].custom_fields['wpcf-normalheadline_' + store.state.language]"></PageHeadline>
                                   <div v-html="filterBR(contentwp[store.state.roomId].custom_fields['wpcf-normalwysiwyg_' + store.state.language])" :class="store.state.typoSwitch ? 'larger':'normal'"></div>
                         </div> -->
                    <!-- </div> -->

                    <div class="basefull-container" :style="kSBackground()" v-if="checkForRaumSeiten()==true">
                         <div class="base-c1" >                
                                   
                              <transition name="fadepage"  mode="out-in" appear>
                              <div :key="store.state.roomPageId" :class="store.state.typoSwitch ? 'larger':'normal'">
                                   <PageHeadline :headline="contentwp[store.state.roomId].children[store.state.roomPageId].custom_fields['wpcf-element_' + store.state.language]" v-touch:swipe.right="prevRoomPage" v-touch:swipe.left="nextRoomPage"></PageHeadline>
                                   <!-- <PageHeadline :headline="contentwp[store.state.roomId].children[store.state.roomPageId].custom_fields['wpcf-element_' + store.state.language]"></PageHeadline> -->
                                   <p v-html="filterBR(contentwp[store.state.roomId].children[store.state.roomPageId].custom_fields['wpcf-text_' + store.state.language])" v-touch:swipe.right="prevRoomPage" v-touch:swipe.left="nextRoomPage"></p> 
                                   <!-- <p v-html="filterBR(contentwp[store.state.roomId].children[store.state.roomPageId].custom_fields['wpcf-text_' + store.state.language])"></p>  -->
                              </div>
                              </transition>
                              <PageLeftRight></PageLeftRight>  
                              <hr>

                              <div class="grid-image__master">
                                   <transition name="fadepage" mode="out-in" appear>
                                        <div class="grid-image__element" v-if="contentwp[store.state.roomId].children[store.state.roomPageId].custom_fields['wpcf-bild1'] !=''" :key="contentwp[store.state.roomId].children[store.state.roomPageId].custom_fields['wpcf-bild1']" @click="openImage(contentwp[store.state.roomId].children[store.state.roomPageId].custom_fields['wpcf-bild1'])">
                                             <!-- <VueImage width="100%" class="image" :src="contentwp[store.state.roomId].children[store.state.roomPageId].custom_fields['wpcf-bild1']"/> -->
                                             <img :src="contentwp[store.state.roomId].children[store.state.roomPageId].custom_fields['wpcf-bild1']" alt="">
                                             <div class="ut" v-html="contentwp[store.state.roomId].children[store.state.roomPageId].custom_fields['wpcf-bild1text_de']"></div>
                                        </div>
                                   </transition>
                              
                                   <transition name="fadepage" mode="out-in" appear>
                                        <div class="grid-image__element" v-if="contentwp[store.state.roomId].children[store.state.roomPageId].custom_fields['wpcf-bild2'] !=''" :key="contentwp[store.state.roomId].children[store.state.roomPageId].custom_fields['wpcf-bild2']"  @click="openImage(contentwp[store.state.roomId].children[store.state.roomPageId].custom_fields['wpcf-bild2'])">
                                        <img :src="contentwp[store.state.roomId].children[store.state.roomPageId].custom_fields['wpcf-bild2']" alt="">
                                        <div class="ut" v-html="contentwp[store.state.roomId].children[store.state.roomPageId].custom_fields['wpcf-bild2text_de']"></div>
                                        </div>
                                   </transition>

                                   <transition name="fadepage" mode="out-in" appear>
                                        <div class="grid-image__element" v-if="contentwp[store.state.roomId].children[store.state.roomPageId].custom_fields['wpcf-bild3'] !=''" :key="contentwp[store.state.roomId].children[store.state.roomPageId].custom_fields['wpcf-bild3']"  @click="openImage(contentwp[store.state.roomId].children[store.state.roomPageId].custom_fields['wpcf-bild3'])">
                                        <img :src="contentwp[store.state.roomId].children[store.state.roomPageId].custom_fields['wpcf-bild3']" alt="">        
                                        <div class="ut" v-html="contentwp[store.state.roomId].children[store.state.roomPageId].custom_fields['wpcf-bild3text_de']"></div>
                                   </div>
                                   </transition>
                              </div>
                              <p v-html="contentui.ui.imageinfo[language]"></p>         
                         </div>
                                        
                    </div>                   
                         
               </div>
               </transition>

               <!-- <div class="basefull-container">      -->
               <div class="content-footer">
                    <div class="button-pfeil-links" @click="prevRoom()" :style="showRoomButton('left')">
                         <img src="../assets/svgs/pfeil-links.svg" alt="">
                         <p :class="store.state.typoSwitch ? 'larg':'leer'" v-html="props.contentui.ui.prevroom[store.state.language]"></p>   
                         <!-- <p :html="uicontent.ui.prev['de']"></p>    -->
                    </div>
                    <div class="footer-line"></div>
                    <div class="button-pfeil-rechts" @click="nextRoom()" :style="showRoomButton('right')">
                         <p :class="store.state.typoSwitch ? 'larg':'leer'" v-html="props.contentui.ui.nextroom[store.state.language]"></p>
                         <img src="../assets/svgs/pfeil-rechts.svg" alt="">
                         <!-- <p :html="uicontent.ui.next['de']"></p>    -->
                    </div>         
               </div> 
     
          </div>

     
          <!-- <transition name="fadepage"  mode="out-in" appear :key="store.state.showModal==true"> -->
          <div class="absolute top-28 left-0 w-full h-full bg-white flex flex-col items-center h-screen" v-if="store.state.showModal==true" :key="store.state.showModal==true">
               <div class="w-8 h-8 self-end" @click="store.state.showModal=false">
                    <!-- <img src="../assets/svgs/button-close-2.svg" alt=""> -->
               </div>
               <div class="w-4/5 h-4/5 flex flex-col self-center align-middle justify-center bg-white w-auto">
                    <div class="w-8 h-8 self-end" @click="store.state.showModal=false">
                         <img src="../assets/svgs/button-close-2.svg" alt="">
                    </div>
                    <!-- <img class="block h-auto w-full object-cover self-center justify-center" style="max-width:1180px;" :src="imageName" alt=""> -->
                    <div class ="h-full bg-no-repeat bg-center" style ="background-size:contain;background-color:white ;" :style="bgImage()">
                         <!-- <img class="block h-auto w-full object-cover self-center justify-center" style="max-width:100%;" :src="imageName" alt=""> -->
                    </div>
               
               </div>
          </div>
          <!-- </transition> -->

          <!-- <div id="iArea" class="background-color: #ff00ff;position: absolute; top:'0px'; left: '0px'; width: '100%'">
                    <img src="https://picsum.photos/500/500" width="500" height="500" />
          </div> -->
     </div>
</template>



<script>
import { onMounted, onUnmounted, onBeforeUnmount  } from 'vue'; // ref, onMounted, computed, getCurrentInstance
import { useRoute, useRouter } from 'vue-router'  // , useRoute, useRouter
import { ref } from 'vue'; // ref, onMounted, computed
import { useStore } from 'vuex'

// import VueImage from '../components/VueExpandableImage.vue';

/* !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

Seitenreihenfolge wurde im Originalsatz verändert
126=128 und 127=129  => keine Auswirkungen aufs CMS
290 und 291 sind gleich, deshalb wurde ab 292 die Seitenzahl verringert.

=> maxBibel von 347 => 346


!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! */


import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import "swiper/swiper-bundle.css";
// import "swiper/css/pagination";
import { Pagination } from "swiper";
import useEmitter from '../composables/useEmitter'

import PageHeadline from '../components/PageHeadline.vue';
// import SmallHeadline from '../components/SmallHeadline.vue'; // für Zusatzinfos
import PageLeftRight from '../components/PageLeftRight.vue';
// import VueImage from '../components/VueExpandableImage.vue';


export default {
     
     name: "Contentview",

     props: {
          contentui:Object,
          content: Object,
          contentwp: Object,
          language: String,
     },

     components: { 
          Swiper,
          SwiperSlide,
          PageHeadline,
          PageLeftRight,
          // VueImage,
     },

     setup(props) { // props
          const emitter = useEmitter();

          // let bibelstellen = ref([ ])
          // var panzoom = require('panzoom');  // https://github.com/anvaka/panzoom
          // let iZoomer = "";

          let store = useStore()
          const router = useRouter();
          const route = useRoute();
          const imageName = ref("../assets/map/map-bg-2.jpg");
          store.state.showModal=false;

          // let roomId = ref(route.params.roomId);
          // let roomPage = parseInt(route.params.pageId);
          
          // let showToggleMarker = ref(false);
          // let showMarker = ref("visible")
          // console.log("setup route.params.roomId", route.params.roomId);
          // let currBibel = ref(1);
          // let maxBibel = ref(346);
          // let path = ref("/bibel/bibel-base.jpg")
          // let wand = ref(1);

          // let dropDownOptions = [ 
          //               "Pater-Noster-Diagramm","Widmungsgedicht","Hieronymus: Prolog Matthäus-Evangelium","Hieronymus: Prolog Matthäus-Evangelium",
          //               "Brief an Carpianus","Prolog  Matthäus-Evangelium","Kapitelübersicht Matthäus-Evangelium",
          //               "Kanontafeln","Matthäus-Evangelium","Leer","Prolog Markus-Evangelium","Kapitelübersicht Markus-Evangelium",
          //               "Markus-Evangelium","Prolog Lukas-Evangelium","Kapitelübersicht Lukas-Evangelium","Lukas-Evangelium",
          //               "Prolog Johannes-Evangelium","Kapitelübersicht Johannes-Evangelium","Johannes-Evangelium","Federproben und Männerkopf","Tage des Kirchenjahres","Kolophon"
          //            ]
          // let showAll = ref(true);
          // let selectedContent = ref({});
          // let evangeliarSubMenuLong = ref("submenüText in lang")
          let externIndex = ref(0);
          // let auswahlWand = ref(false);
          let time = ref(0);
          let swiperIndex = ref(1);
          let swiper = null;
          // let evangeliarSeiten = ref([]);

          const bgImage = () => {
               // console.log("bgDummyImage", imageArray.value[imagePos.value]);
               return {
                    backgroundImage: 'url(' + imageName.value + ')',
               }
          }


          const prevRoomPage = () => {
               if ( store.state.roomPageId > 0 ) {
                   store.state.roomPageId = store.state.roomPageId -1
                    // router.push({ name: "rundgangpage", params: { roomId: store.state.roomId, pageId:  store.state.roomPageId}, props: true})   // , language: store.state.language
                    router.push({ path: "/rundgangpage" + "/" + store.state.roomId + "/" + store.state.roomPageId})   // , language: store.state.language
               } else {
                   store.state.roomPageId = store.state.maxRoomPage -1;
                    // router.push({ name: "rundgangpage", params: { roomId: store.state.roomId, pageId: store.state.roomPageId}, props: true})   // , language: store.state.language
                    router.push({ path: "/rundgangpage" + "/" + store.state.roomId + "/" + store.state.roomPageId}) 
               }
               // console.log("prevRoomPage", store.state.roomPageId )
               controlPincode()
           }
   
           const nextRoomPage = () => {
               if (store.state.roomPageId < store.state.maxRoomPage-1){
                   store.state.roomPageId = store.state.roomPageId +1;
                    // router.push({ name: "rundgangpage", params: { roomId: store.state.roomId,  pageId: store.state.roomPageId}, props: true})   // , language: store.state.language  // 
                    router.push({ path: "/rundgangpage" + "/" + store.state.roomId + "/" + store.state.roomPageId}) 
               } else {
                   store.state.roomPageId = 0;
                    // router.push({ name: "rundgangpage", params: { roomId: store.state.roomId,  pageId: store.state.roomPageId}, props: true})   // , language: store.state.language  // 
                    router.push({ path: "/rundgangpage" + "/" + store.state.roomId + "/" + store.state.roomPageId}) 
               }
               controlPincode()
               // console.log("nextRoomPage", store.state.roomPageId)
           }

           const openImage = (imagename) => {
               // console.log(imagename);
               var strAry = imagename.split("/");
               strAry[6] = ""
               strAry[5] = "big"
               imageName.value = strAry.join("/");
               // console.log("xxx", imageName);
               store.state.showModal = true;
               setTimeout(() =>{ // console.log("scroller timeout")
                    window.scrollTo(0, 0);
               }, 500)
           }

          //  1. grün #b8db1d
          //  2. orange: #f5a94b
          //  3. blau: #17c3c7
          //  4. gelb: #fad910

          const kSHr = () => {
               if (store.state.kontrastSwitch == false) {
                    if (store.state.roomId == 0){
                         return {
                              backgroundColor: '#b8db1d',
                              border: '2px solid #b8db1d',
                         }
                    } else if (store.state.roomId == 1){
                         return {
                              backgroundColor: '#f5a94b',
                              border: '2px solid #f5a94b',
                         }
                    } else if (store.state.roomId == 2){
                         return {
                              backgroundColor: '#17c3c7',
                              border: '2px solid #17c3c7',
                         }
                    } else if (store.state.roomId == 3){
                         return {
                              backgroundColor: '#fad910',
                              border: '2px solid #fad910',
                         }
                    }
                    
               } else {
                    return {
                         backgroundColor: '#ffffff',
                         border: '2px solid #ffffff',
                    }
               } 
          }

          const onSwiper = (swipertemp) => {
            swiper = swipertemp
            // console.log(swiper);
          };
        
          const onSlideChange = (swiper) => {
               // console.log("activeIndex", swiper.activeIndex);
               swiperIndex.value = swiper.activeIndex
               if (swiperIndex.value == 0) {
                    swiperIndex.value = 3
               } else if (swiperIndex.value ==4) {
                    swiperIndex.value = 1
               }
               time.value = 0;
               // console.log('slide change');
          };

          const onLeft = () => {
            // console.log("left")
            swiper.slideNext();
        }

        const onRight = () => {
            //console.log("right")
            swiper.slideNext();
        }

     //    const showPageButton = (mode) => {
     //      if (mode == "left"){
     //           if ( currBibel.value>1 ) {
     //                return { opacity: 1.0 }
     //           } else {
     //                return { opacity: 0.0 }
     //           }
     //      } else if (mode == "right"){
     //           if ( currBibel.value < maxBibel.value ) {
     //                return { opacity: 1.0 }
     //           } else {
     //                return { opacity: 0.0 }
     //           }
     //      }
     //    }

        const showRoomButton = (mode) => {
          if (mode == "left"){
               if ( store.state.roomId > 0 ) {
                    return { opacity: 1.0 }
               } else {
                    return { opacity: 0.0 }
               }
          } else if (mode == "right"){
               if ( store.state.roomId < 3 ) {
                    return { opacity: 1.0 }
               } else {
                    return { opacity: 0.0 }
               }
          }
        }

        const kSBackground = () => {
               if (store.state.kontrastSwitch == false) {
                    return {
                         backgroundColor: '#11111133',
                    }
               } else {
                    return {
                         backgroundColor: '#000000',
                    }
               } 
        }

        const kSKachel = () => {
               if (store.state.kontrastSwitch == false) {
                    return {
                         backgroundColor: '#ffffffdd',
                         // border: '1px solid #1e211d',
                    }
               } else {
                    return {
                         backgroundColor: '#ffffff',
                         // border: '1px solid #000000',
                    }
               } 
        }

        const kSKachelActive = () => {
               // console.log("kSKachelActive")
               if (store.state.kontrastSwitch == false) {
                    return {
                         backgroundColor: '#ffffff33',
                         // border: '2px solid #4c5749',
                    }
               } else {
                    return {
                         backgroundColor: '#000000',
                         // border: '1px solid #ffffff',
                    }
               } 
        }

        const kSKachelFont = () => {
               if (store.state.kontrastSwitch == false) {
                    return {
                         color: '#000000',
                    }
               } else {
                    return {
                         color: '#000000',
                    }
               } 
        }

        const kSKachelFontActive = () => {
               // console.log("kSKachelActive")
               if (store.state.kontrastSwitch == false) {
                    return {
                         color: '#ffffff',
                    }
               } else {
                    return {
                         color: '#ffffff',
                    }
               } 
        }

        const contentRoom = (index) => {
               // console.log("contentroom", index);
               store.state.roomPageId = 0; // XXX
               // maxRoomPage
               // store.dispatch("setRoomId", index);
               // xxx
               store.state.roomId = index;
               store.state.roomPageId = 0;
               store.state.maxRoomPage = props.contentwp[store.state.roomId].children.length;  //props.contentwp.pages[store.state.roomPageId].length;
               // console.log("contentroom", index, store.state.maxRoomPage);

               router.push({ path: "/rundgangpage" + "/" + store.state.roomId + "/" + store.state.roomPageId});
               
               // store.state.showRundgang = true;
               // console.log("contentroom 3");
               controlPincode()
               // console.log("contentroom 4");
               // setTimeout(() =>{  // console.log("scroller timeout")
               //      window.scrollTo(0, 0);
               // }, 500)
          }

          const nextRoom = () => {
               contentRoom(store.state.roomId +1)
               controlPincode()
               setTimeout(() =>{ // console.log("scroller timeout")
                    window.scrollTo(0, 0);
               }, 500)
          }

          const prevRoom = () => {
              
               contentRoom(store.state.roomId -1)
               controlPincode()
               setTimeout(() =>{ // console.log("scroller timeout")
                    window.scrollTo(0, 0);
               }, 500)
          }

          const controlPincode = () => {
               if (store.state.roomPageId > 8) {
                    store.state.pincode = String(store.state.roomId +1) + "" + String(store.state.roomPageId +1);
               } else {
                    store.state.pincode = String(store.state.roomId +1) + "0" + String(store.state.roomPageId +1);
               }
               
          }

          const filterBR = (xxx) => {
               //console.log(xxx);
               // return "<H1>hallo</H1>"
               // let  yyy = xxx /// .replace('<br />','\r' );
               //console.log(xxx);
               let yyy = xxx.replace(/(?:\r\n|\r|\n)/g, '<br/>');
               //console.log(yyy);
               // console.log(yyy)
               return  yyy
               // return "<H1>hallo</H1>"
          }

          const checkForRaumSeiten = () => {
               store.state.maxRoomPage = props.contentwp[store.state.roomId].children.length;
               if ( props.contentwp[store.state.roomId].children.length > 0 ){
                    // console.log("checkForRaumSeiten true")
                    return true;
               } else {
                    // console.log("checkForRaumSeiten false")
                    return false;
               }
               
          }
          
          const kSDekoline = () => {
               if (store.state.kontrastSwitch == false) {
                    return {
                         backgroundColor: '#ffffff',
                    }
               } else {
                    return {
                         backgroundColor: '#ffffff',
                    }
               } 
        }

          // watch(() =>  props.language, (first, second) => {
          //      console.log( "Watchstore language function called with args:", first, second);
          //      if (auswahlWand.value==false ){
          //           const results = props.content.evangeliarSubMenu.filter(menu => ( (menu.seite[0] <= currBibel.value) && (menu.seite[1] >= currBibel.value) ) );
          //           evangeliarSubMenuLong.value = results[0]["textlang_" + props.language]
          //      } else {
          //           evangeliarSubMenuLong.value = store.state.contentUi.ui.auswahlwand[store.state.language]
          //           console.log("auswahlWand evangeliarSubMenuLong", evangeliarSubMenuLong.value);
          //      }
          // });

          // watch(() =>  route.params.pageId, (first, second) => {
          //      console.log( "router params changed", first, second);
          // });
          
          // store.state.roomPageId = to.params.pageId;
          // onCreated(() => {
          //      console.log("created")
          // })
          onUnmounted(() => {
               // refzitataudio.value.stop();
          })

          onBeforeUnmount(() => {
               // refzitataudio.value.stop();
          })

          onMounted(() => {
               store.state.roomPageId = 1;

               // let element = document.getElementById('iArea');
               // iZoomer = panzoom(element, {initialZoom: 0.3, minZoom: 0.3, maxZoom: 5, bounds: true, boundsPadding: 0.3,  autocenter: false,
               //      onClick(e) {
               //           console.log("e.target.id", e.target.id)
               //           if ( (e.target.id != undefined) && (e.target.id != '') ){
               //                // openRouterSite(e.target.id);
               //           } 
               //      }
               // })



               // console.log("onMounted", props.content)
               // console.log("onMounted contentWp", props.contentwp[store.state.roomId].custom_fields['wpcf-ad_'  + props.language])

               // init der bibelseiten erst einmal leer anlegen nur mit nr.
               // for (let i = 1; i <= maxBibel.value; i++) {
               //      let o = { 
               //           "nr": i,
               //           "markerPos": [],
               //           "markerText": [],
               //           // "seitenText": []
               //           "seitenText": {},
               //           "mapPos": [],
               //      }
               //      evangeliarSeiten.value.push(o)
               // }

               emitter.on("AUDIO_ZITAT", message => {
                    console.log("AUDIO_ZITAT", message)

                    // soll nur da hinscrollen
                    // getCurrentInstance().ctx.$refs["bibel" + message].scrollIntoView({ behavior: "smooth" });
                    // document.getElementById("bs"+message).scrollTo({ behavior: "smooth", top: 0 });

                    var my_element = document.getElementById("bs"+message);
                    console.log("my_element", my_element)
                    my_element.scrollIntoView({
                         behavior: "smooth",
                         block: "start",
                         inline: "nearest"
                         });

               })

               if(store.state.roomId == 0){
                    // store.state.setRoomId( parseInt(route.params.roomId));
                    store.dispatch("setRoomId", parseInt(route.params.roomId) );
                    store.state.showRundgang = true;
               }

               // console.log("roomPage in content", roomPage);
               // if (roomPage == undefined){
               //      roomPage = 1;
               // }
               // console.log("roomPage in content", roomPage);
               // console.log("EXXXTERN MOUNTED PAGEID", route.params.pageId);

               if (route.params.pageId != null || route.params.pageId == ""){
                    store.state.roomPageId = parseInt(route.params.pageId)
               } else {
                    store.state.roomPageId = 1;
               }

               store.state.showRundgang = true
              
          })

          
          return {
               props,
               externIndex,
               onSwiper,
               onSlideChange,
               onLeft,
               onRight,
               route,
               contentRoom,
               modules: [Pagination],
               nextRoom,
               prevRoom,
               showRoomButton,
               checkForRaumSeiten,
               kSBackground,
               kSKachel,
               kSKachelActive,
               kSKachelFont,
               kSKachelFontActive,
               kSDekoline,
               kSHr, 
               prevRoomPage,
               nextRoomPage,
               filterBR,
               // iZoomer,
               store,
               openImage,
               imageName,
               bgImage,
          }
     },

     // data() {
     //      return {
              
     //      };
     // },
     // methods: {
          
     // },

     // mounted() {
          
     // },
     
}
</script>



<style lang="scss" scoped>
@import "../css/variables.scss";


div .ut {
 color: white;
 margin-bottom: 20px;
}

@media ( max-width: 576px )  {
     .ut {
          font-family: FontLight;
          font-size: 12px;
          line-height: 14px;
          color: $textcolor;
          margin-bottom: 1.15rem;
          white-space: pre-line;
     }
}

.grid-image__master {
     display: grid;
     grid-template-columns: repeat(3, 1fr);
     grid-template-rows: 1fr;
     grid-column-gap: 12px;
     grid-row-gap: 14px;
}

@media ( max-width: 576px )  {
     .grid-image__master {
          display: grid;
          grid-template-columns: repeat(1, 1fr);
          grid-template-rows: 3fr; 
     }    
}

@media ( min-width: 577px ) and ( max-width: 1024px )  {
     .grid-image__master {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-template-rows: 1fr;
          grid-column-gap: 12px;
          grid-row-gap: 14px;
     }
}

// @media ( min-width: 480px ) and ( max-width: 576px )  {    
// }



.grid-image__element {
     
}


.headline-container {
     display: flex;
     // background-color: orange;
}

:deep(.swiper-pagination .swiper-pagination-bullet ) {
     opacity: 1;
     border: white solid 1px;
     background-color: transparent;
}

:deep(.swiper-pagination .swiper-pagination-bullet-active) {
     background-color: rgb(255, 255, 255);
}

@media ( min-width: 480px ) and ( max-width: 576px ) {
     :deep(.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal) {
     bottom: 0px;
     left: 0;
     width: 100%;;
     }
}


button {
  background-color: #e4e4e4; 
  border-radius: 8px;
  border: none;
  color: rgb(0, 0, 0);
  margin: 0.4rem;
  padding: 0.8rem 1.6rem;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1.0rem;
}


.content-block{
     padding-top: 0.3rem;
     // display:block;
     // position: absolute;

}
// #container-3d {
//      margin: 0 auto;
//      // width: 400px;
//      // height: 400px;
//      width: 70%;
//      // height: 80%;
//      // background-color: red;
// }



svg {  // xxxx hier könnte die maximale breite angegeben werden
    max-width: 844px;
    max-height: 100%;
}

// .bibel-container {
//      // display: grid;
//      // grid-template-columns: 33% 67%;
//      // grid-template-rows: auto;
//      // grid-template-areas: "bt bi" ;
//      // padding-top: 20px;

//      // grid-auto-flow: reverse;
//      display: grid;
//      grid-template-columns: 100%;
//      grid-template-areas: "bi"
//                           "bt" ;
//      padding-top: 20px;

// }

// .bibel-text {
//      grid-area: bt;
//      align-items: center;
   
// }

// .bibel-image {
//      display: flex;
//      justify-content:center;
//      grid-area: bi;
//      // background-color: green;
//      // max-width: 100%;
// }

// .map-container {
//      display: flex;
//      justify-content:center;
//      // grid-area: bi;
//      // background-color: green;
//      // max-width: 100%;
// }

img {
    max-width: 100%;
    max-height: 100%;
}

.button-pfeil-links {
     display: flex;
     align-items: center;
     justify-content: flex-start;
     // background-color: #f80000;
     transition: all 0.5s;
     width:20%;
     // height: 2rem; 
}

.button-pfeil-rechts {
     display: flex;
     align-items: center;
     justify-content: flex-end;
     // background-color: #f80000;
     width:20%;
     transition: all 0.5s;
     // height: 2rem; 
}

.button-pfeil-rechts p {
   margin-bottom: 0px;
   margin-top: 0px;
   margin-right: 10px;
}

.button-pfeil-links p {
   margin-bottom: 0px;
   margin-top: 0px;
   margin-left: 10px;
}

.headline-container hr {
    opacity: 0.0;
}

.page-button-links {
     display: flex;
     align-items: center;
     justify-content: flex-start;
     // background-color: #f80000;
     width:10%;
     height: 2rem;
     transition: all 0.5s;
}

.page-button-rechts {
     display: flex;
     align-items: center;
     justify-content: flex-end;
     // background-color: #f80000;
     width:10%;
     height: 2rem;
     transition: all 0.5s;
}

.button-base {
     display: flex;
     //  min-width: 75px;
     height: 24px;
     width: auto;
     margin: 0px 12px 12px 0px;
     background-color: #f80000;
     border-radius: 0px;
     align-content: center;
     transition: all 0.3s;
}

@media (max-width: 480px) {
     .button-base {
          margin: 0px 0px 12px 0px;
     }
}
.button-base p {
     font-family: FontSerifItalic;
     color: white;
     font-size: 16px;
     line-height: 0px;
     text-align: center;
     padding: 0px;
     margin: 0px;
}
.flex-row-left {
     display: flex;
     flex-direction: row;
     // justify-content: left;
     padding-bottom: 20px;
     align-items: left;
     // justify-content:space-between; //  space-around;

}

@media (max-width: 576px) {
     .flex-row-left {
     
          flex-direction: column;
          // justify-content: left;
          padding-bottom: 20px;
          align-items: left;
          justify-content:space-between; //  space-around;
     }
}


// .flex-row-end {
//      display: flex;
//      justify-content:space-between; //  space-around;
//      align-items: center;
//      // background-color: #2c5777;
// }

.wrapper {
    width: 100%;
    min-height: 100vh;
    background-color: #f80000;
    margin: 0;
    padding: 20px;
  }

.comment {
font-style: italic;
color: lightgrey;
}

// @media (max-width: 60rem) {
@media (max-width: 768px) {
     .bibel-container{
          grid-auto-flow: reverse;
          display: grid;
          grid-template-columns: 100%;
          grid-template-rows: auto ;
          grid-template-areas: "bi"
                               "bt" ;
     }

     svg {
          max-width: 705px;
          max-height: 100%;
     }

}

.swiper-container {
     // position: relative;
     // left: 0px;
     // top: 0px;
     width: 100%;
     height: 150px;
     z-index: 0;
     // width: 100%;
     // display: flex;
     // background-color: #000000;
}

@media (max-width: 576px) {

     .swiper-container {
     // position: relative;
     // left: 0px;
     // top: 0px;
     width: 100%;
     height: 120px;
     z-index: 0;
     // width: 100%;
     // display: flex;
     // background-color: #04ff04;
     }

}

@media (max-width: 480px) {
     .swiper-container {
     // position: relative;
     // left: 0px;
     // top: 0px;
     width: 100%;
     height: 120px;
     z-index: 0;
     // width: 100%;
     // display: flex;
     // background-color: #ff04c9;
     }
}


.testslide{
     width: 156px;
     height: 116px;
     background-color: red;
}

.swiper-pagination{
     width: 100%;
     height: 30px;
     // background-color: orange;
}


.bgbibel {
    background: radial-gradient(#fff 20%, #b9b9b9 100%);
}


</style>


<style lang="scss" scoped>
@import "../css/variables.scss";
    
     .content-footer {
          display: flex;
          flex-direction: row;
          width: 100%;
          height: 5rem;
          justify-content: space-between;
          align-items: center;
          background-color: $dekocolor15proz;
          margin-top: 2.5rem;
     }

     .content-footer > div {
          width: 200px;
          height: 70px;
          // flex-wrap: wrap;
          
          // background-color: red;
          
     }

     .content-footer > div > p {
          font-family: FontSerifItalic;
          color: white;
          font-size: 16px;
     }


     .content-footer > div:nth-child(1) {
          text-align: left;
          margin-left: 36px;
     }

     .content-footer > div:nth-child(3) {
          text-align: right;
          margin-right: 36px;
     }

     .content-footer > .footer-line {
          width: 2px;
          height: 36px;
          background-color: white;
          opacity: 0.20;
          vertical-align: center;
     }

     .swiper-slide {
          // width: 100% !important;
          // width: 172px !important;
          // justify-content: space-between;
     }

</style>